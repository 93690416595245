@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Inter;
  /* font-family: "Work Sans", sans-serif; */
  font-size: 62.5%;
}

html,
body {
  /* font-family: "Inter", sans-serif; */
  overflow-x: hidden;
  background-color: white;
}
h1 {
  font-size: 2.3rem;
  font-weight: 480;
  color: darkslategray;
  font-family: sans-serif;
  line-height: 1.4;
}
h2 {
  font-size: 1.5rem;
  font-weight: 480;
  line-height: 1.2;
  color: darkslategray;
  font-family: sans-serif;
}
h3 {
  font-size: 1.2rem;
  color: grey;
}
p {
  font-size: 1.2rem; 
  line-height: 1.3;
  color: grey;
}
i {
  font-size: 1.2rem; 
  line-height: 1.3;
  color: grey;
}
.p-colored {
  color: darkslategrey;
  font-weight: 450;
}
p a{
  font-size: 1.2rem; 
  line-height: 1.5;
  color: blue;
}

@media (max-width: 700px) {
  .masonry-grid {
    gap: 13px !important; /* Smaller gap for mobile */
  }
  .post {
    margin-bottom: 25px !important; /* Smaller margin for mobile */
    padding: 0px !important;
  }
}