.about-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 4vw auto;
    gap: 2vw;
    justify-content: center;

    /* border: black 1px solid; */
}
.hello-container {
    display: flex;
    flex-direction: column;
    width: 40%;
    max-width: 500px;

    /* border: pink 1px solid; */
}
.hello-container img {
    width: 300px; /* Set the width of the image */
    height: auto; /* Maintain the aspect ratio */
    border-radius: 5px;
}
.bio-cv-container {
    display: flex;
    flex-direction: column;    
    width: 60%;
    max-width: 750px;
    gap: 2vw;

    /* border: blue 1px solid; */
}
.bio-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* border: black 1px solid; */
}
.cv-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* border: black 1px solid; */
}



@media screen and (max-width: 800px) {
    .about-container {
        flex-direction: column;
    }
    .hello-container {
        width: 100%;
    }
    .bio-cv-container {
        width: 100%;
    }
}


