.projects-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2.5vw;
    margin: 4vw 0;

    /* border: black 1px solid; */
}
.project-item {
    height: 320px !important;
    width: 100%;
    overflow: hidden;
    justify-content: flex-start;
    align-items: flex-start;
    border: lightgrey 2px solid;
}
.project-item a{
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
}
.project-item-meta {
    display: flex;
    flex-direction: column;
    width: 380px;
    padding: 0 30px;
    gap: 10px;
    text-align: center;

    /* border: black 1px solid; */
}
.project-item-meta p{
    text-align: center;
}
.project-item-images {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    width: 100%;
    height: 270px;

    overflow: hidden;
    padding: 0 1vw;

    /* border: red 1px solid; */
}
.project-item-images-box {
    height: 270px;
    width: 270px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    border-radius: 5px;
    /* border: blue 1px solid; */
}
.project-item-images-box img {
    height: 100%;
    width: 100%;
    object-fit: contain;

    border-radius: 5px;
    transition: transform 0.3s ease;
}
.project-item:hover {
    transform: scale(1.01);
    border: black 2px solid;
}
.project-intro-text a {
    font-size: 1.2rem; 
    line-height: 1.3;
    color: blue;
    text-decoration: underline;
}




/* CSS codes for the project detail pages */
/* CSS codes for the project detail pages */
.project-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    width: 100%;
    margin: 4vw 0;
    padding: 0 2vw 0 2vw;

    /* border: 1px black solid; */
}
.project-intro {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1200px;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 5vw;
    gap: 30px;
    padding: 20px 0;

    border-top: 1px grey solid;
    border-bottom: 1px grey solid;

    /* border: 1px black solid; */
}
.project-intro-text {
    text-align: left;
    width: 50%;
}
.project-intro-image {
    display: flex;
    width: 50%;
    align-items: flex-start;
    justify-content: flex-start;
    
    /* border: 2px blue solid; */
}
.project-intro-image img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.project-body {
    /* display: flex;
    flex-wrap: wrap;
    justify-content: center; */

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(550px, 1fr)); 
    width: 100%;
    gap: 2vw;

    margin: 60px 0;
    /* border: 1px solid black;  */
}
.project-body-group {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
    overflow: hidden;

    width: 550px;
    height: 550px;
    
    margin: 10px auto;

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    border-radius: 5px;

    /* border: 2px solid blue; */
}
.project-body-group img {
    max-height: 100%;
    width: 100%;
    object-fit: contain;

    border-radius: 5px;
}
.project-body-group p{
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
}










@media screen and (max-width: 800px) {
    .projects-container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 8vw;
        max-width: 800px;
        margin: 4vw auto;
        padding: 4vw 2vw;
    }

    .project-item {
        height: 290px !important;
    }

    .project-item-meta {
        width: 350px;
        padding: 0 10px;
        /* border: black 1px solid; */
    }

    .project-item-images-box {
        height: 270px;
        width: 270px;
        padding: 0 1vw;    
        /* border: blue 1px solid; */
    }

    .project-intro {
        flex-direction: column;
        width: 100%;
        margin: 8vw 0;
        /* border: 1px black solid; */
    }

    .project-body {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 4vw;
        
        /* border: 1px solid black;  */
    }

    .project-intro-text, .project-intro-image {
        width: 100%;
    }

    .project-body-group {    
        width: 100%;
        margin: 10px;
    
        /* border: 2px solid blue; */
    }

}