.memo-list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 2vw 0 2vw;
    margin: 8vw 0;
    gap: 2vw;
}
.memo-list-container p{
    font-size: 1.2rem;
    line-height: 2;
}
.memo-list-container a{
    font-size: 1.4rem;
    color: darkslategray;
    font-weight: 450;
}
.memo-memo-container {
    display: flex;
    flex-direction: column;
    max-width: 700px;
    
    /* border: black 1px solid; */
}
.memo-memo-container p{
    max-width: 700px;
    /* border: black 1px solid; */
}


/* For the memo detailed posts */
/* For the memo detailed posts */
.memo-contents-container {
    display: flex;
    flex-direction: column;
    max-width: 700px;
    margin: 8vw auto;
    gap: 4vw;
    /* border: 2px pink solid; */
}
.memo-title-container {
    color: grey;
    /* border: 2px black solid; */
}
.memo-title-container h2{
    color: grey;
}
.memo-body-container p{
    color: black;
    font-size: 1.3rem;
    white-space: pre-wrap;

    /* border: 2px black solid; */
}
.memo-body-container a p{
    color: blue;
    font-size: 1.3rem;
    text-decoration: underline;
}



@media screen and (max-width: 800px) {

    .memo-list-container {
        gap: 8vw;
        max-width: 800px;
        margin: 8vw 0;
        padding: 4vw 2vw;
    }
    .memo-memo-container {
        max-width: 800px;
    }

    .memo-contents-container {
        max-width: 800px;
        margin: 18vw 2vw;
        gap: 10vw;
    }
}