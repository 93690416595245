/* OVERALL LAYOUT - HEADER AND FOOTER */
/* main {
    position: fixed;
    top: 0;
    left: 0;
  
    width: 100%;
    height: 100%;
  
    display: flex;
    flex-direction: column;
    align-items: center;
    
    overflow: scroll;
    margin: 0px;
    background-color: white;
  } */
  header {
    width: 100%;
  
    align-items: center;
    /* border: black 1px solid; */
  }
  .header-contents {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 30px 0 20px 0;
    width: 100%;
    border-bottom: grey 1.3px solid;
  
    align-items: center;
    z-index: 10;
  
    /* border: black 1px solid; */
  }
  #logo {
    font-size: 2rem;
    font-weight: 550;
    color: darkslategrey;
    text-decoration: none;
    line-height: 0.8;
  
    /* border: black 1px solid; */
  }
  nav ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  nav ul li {
    margin-left: 2rem;
  }
  nav ul li a {
    text-decoration: none;
    color: darkslategrey; 
    font-size: 1.2rem;
  }
  nav ul li a:hover {
    text-decoration: underline;
  }
  footer {
    background-color: none;
    width: 100%;
  }
  .footer-contents {
    background-color: none;
    border-top: 1px solid ;
    border-color: lightgrey;
  
    padding: 25px 0;
    width: 100%;
  }
  .footer-contents a{
    text-decoration: none;
    color: darkslategrey;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    text-decoration: none;
    font-size: 1.2rem;
  }